<template>
  <div>
    <v-app id="inspire">
      <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-img  height="100vh" src="../../assets/Images/homepage2.png">
            <v-layout wrap justify-center fill-height>
              <v-flex xs10 align-self-center>
                <v-layout wrap justify-end pb-5>
                  <v-flex xs7 pt-6>
                    <v-layout wrap fill-height>
                      <v-flex xs12 align-self-center text-left>
                        <span style="
                            font-family: brightfont;
                            font-size: 28px;
                            color: white;
                            letter-spacing: 1px;
                          ">Story of 1000 planters</span>
                        <br />
                        <span style="
                            font-family: mainfont;
                            font-size: 28px;
                            color: white;
                            letter-spacing: 1px;
                          ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg4 align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-card width="330px" height="390px" outlined color>
                              <v-layout wrap justify-center>
                                <v-flex xs12>
                                  <v-layout wrap justify-center>
                                    <v-flex xs12>
                                      <v-layout wrap justify-center>
                                        <v-flex xs9 pt-7 text-left>
                                          <span style="
                                              font-family: mainfont;
                                              font-size: 20px;
                                              color: #13736f;
                                              letter-spacing: 1px;
                                              font-weight: bold;
                                            ">
                                            Login
                                          </span>
                                        </v-flex>
                                        <v-flex pt-1 xs9 text-left>
                                          <span style="
                                              font-family: mainfont;
                                              font-size: 15px;
                                              color: #000;
                                            ">
                                            Welcome
                                          </span>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex pt-1 xs12 text-left>
                                      <v-layout wrap justify-center>
                                        <v-flex xs9>
                                          <span style="
                                              font-family: mainfont;
                                              font-size: 12px;
                                              color: #000;
                                              font-weight: bold;
                                            ">Phone number</span>
                                          <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#717171" outlined :rules="phoneRules" dense style="
                                                font-family: mainfont;
                                                position: relative;
                                              " v-model="phone" hide-details>
                                              <template v-slot:append>
                                                <div style="
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            font-family: mainfont;
            color: #13736f;
            cursor: pointer;
            padding-right: 12px;
          " @click="verifyPhoneNumber" v-if="shouldShowVerifyButton">
                                                  Verify
                                                </div>
                                              </template>
                                            </v-text-field>
                                          </v-form>
                                        </v-flex>
                                        <v-flex xs9 pt-2>
                                          <span style="
                                              font-family: mainfont;
                                              font-size: 12px;
                                              color: #000;
                                              font-weight: bold;
                                            ">Enter OTP</span>
                                          <v-form @submit.prevent="validateInput">
                                            <!-- <vie-otp-input
                          inputClasses="otp-input"
                          :numInputs="4"
                          separator
                          v-model="otp"
                        /> -->
                                            <v-text-field color="#717171" outlined dense style="font-family: mainfont"
                                              v-model="otp" hide-details :disabled="!showOTPInput"></v-text-field>
                                          </v-form>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>

                                    <v-flex xs9 pt-5 pb-3>
                                      <v-btn v-if="isVerified === false" class="no-uppercase" disabled block
                                        color="#13736f"><span style="
                                            font-family: mainfont;
                                            font-size: 15px;
                                            color: white;
                                            letter-spacing: 1px;
                                            cursor: pointer;
                                          ">Login
                                        </span>
                                      </v-btn>

                                      <v-btn v-else class="no-uppercase" @click="validateInput" dark width="250px"
                                        color="#13736f"><span style="
                                            font-family: mainfont;
                                            font-size: 15px;
                                            color: white;
                                            letter-spacing: 1px;
                                            cursor: pointer;
                                          ">Login
                                        </span>
                                      </v-btn>
                                    </v-flex>

                                    <v-flex xs12 text-center>
                                      <span style="
                                          font-family: mainfont;
                                          font-size: 14px;
                                          color: black;
                                        ">
                                        <strong>New User?</strong>
                                      </span>
                                    </v-flex>
                                    <v-flex Registrtion @click="
                                      $router.push({
                                        path: '/Registration',
                                      })
                                      " xs12 pt-1 pb-3 text-center>
                                      <span style="
                                          font-family: mainfont;
                                          font-size: 14px;
                                          color: #13736f;
                                          letter-spacing: 1px;
                                          cursor: pointer;
                                          font-weight: 600;
                                        ">Create an account</span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout></v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";

import store from "./../../store";
export default {

  data() {
    return {
      ServerError: false,
      showOTPInput: false,
     
      showSnackBar: false,
      timeout: 5000,
      otp: "",
      msg: null,
      isVerified: false,

      phone: null,
      password: null,
    };
  },
  computed: {
    shouldShowVerifyButton() {
      return this.phoneRules.every(rule => rule(this.phone) === true);
    },
    phoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]+$/.test(v) || "Only digits are allowed",
        (v) => (v && v.length === 10) || "Phone number must be 10 digits",
      ];
    },
    isOTPValid() {
      return this.otp.length === 4;
    },
  },
  methods: {
    validateInput() {
      if (!this.phone) {
        this.msg = "Please Provide phone";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    verifyPhoneNumber() {
      axios({
        method: "GET",
        url: "/user/signup/signin/send/otp",
        params: {
          phone: this.phone,
          accessType: "signin",
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.showOTPInput = true;
            this.isVerified = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    login() {
  axios({
    method: "post",
    url: "/user/signup/signin",
    data: {
      phone: this.phone,
      otp: this.otp,
      accessType: "signin",
      test: "tst",
    },
  })
    .then((response) => {
      if (response.data.status) {
        localStorage.setItem("userRole", response.data.role);

        store.commit("loginUser", response.data.token);

        this.$router.push({
          path: "/DashboardPage",
        });
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      store.commit("appLoading", false);
      this.ServerError = true;
      console.log(err);
    });
}

  },
};
</script>
 
<style lang="sass" scoped>
.__card
  max-width: 600px
  margin: 0 auto

.layout-auth

  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0

.layout-authi

  height: 100%
  width: 100%
</style>
<style >
.layout-auth-background {
  background-color: #171515;
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.layout-authi {
  height: 100%;
  width: 100%;
}

.otp-input {
  width: 35px !important;
  height: 35px !important;
  color: black;
  margin-left: 5px;
  font-size: 20px !important;
  border-bottom: 1px solid #d6d6d6;
  outline: 0;
  border-width: 1px 1px 1px;
  text-align: center;
  padding-left: 0;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #c8c8c8;
}

.separator::before {
  margin-right: 0.25em;
}

.separator::after {
  margin-left: 0.25em;
}

@media screen and (max-width: 768px) {
  .otp-input {
    width: 25px !important;
    height: 25px !important;
    padding: 4px !important;
    margin: 0 10px;
    font-size: 15px !important;
    border: 1px solid black;
    outline: 0;
    border-width: 1px 1px 1px;
    text-align: "center";
    padding-left: 12px;
  }
}

.mainbg {
  background-image: linear-gradient(269.6deg, #29807c -31.66%, #29807c);
}

.otp-input {
  border: 1px solid grey;
}

.card-animation {
  /* Set up 3D rotation effect */
  position: relative;
  transition: transform 0.5s;
}

.card-animation:hover {
  animation: rotateCard 0.5s;
}

@keyframes rotateCard {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}
</style>